<template>
  <div class="row">
    <div class="col-12">
      <div class="row row-cols-1 mt-4">
        <main class="mt-3 w-100 m-auto">
          <!-- <div> -->
          <div class="text-center w100 p-0 py-2 align-middle">
            <b class="text-muted">
              <h3>
                <p class="text-primary">懶人包快速選單</p>
              </h3>
            </b>
          </div>
          <div class="row row-cols-md-4 row-cols-2 g-4">
            <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #f4dddf">
                  <h5 class="card-title">亞洲航空、台灣虎航</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=ALL-TPEKIXLOW">台北大阪 五月 亞洲航空 2090、台灣虎航 2099 整理</a></p>
                </div>
              </div>
            </div>
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #ffe900">
                  <h5 class="card-title">泰國亞洲航空</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=FD-KHHNRTLOW">高雄東京成田 3388 整理</a></p>
                </div>
              </div>
            </div> -->
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #faa836">
                  <h5 class="card-title">台灣虎航</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=IT-NSWITCH2"> SWICH2 日本發售日前後(6/1 ~ 6/10)價格整理</a></p>
                </div>
              </div>
            </div> -->
            <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #faa836">
                  <h5 class="card-title">台灣虎航</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=IT-JP1799">日本 1799 價格整理</a></p>
                </div>
              </div>
            </div>
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #ffe900">
                  <h5 class="card-title">亞洲航空</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=D7-S25JPLOW">台北大阪 台北大阪 低價票整理（暑假 2290）</a></p>
                </div>
              </div>
            </div> -->
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #ffe900">
                  <h5 class="card-title">酷航</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=TR-SCOOTPLUS">酷航PLUS優惠活動</a></p>
                </div>
              </div>
            </div> -->
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #faa836">
                  <h5 class="card-title">台灣虎航</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=IT-HSG2399">不走回頭路 - 佐賀</a></p>
                </div>
              </div>
            </div> -->
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: red">
                  <h5 class="card-title">泰國獅子航空</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=SL-S25JPLOW">東京、沖繩、名古屋低價票整理</a></p>
                </div>
              </div>
            </div> -->
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #f4dddf">
                  <h5 class="card-title">泰國亞洲航空、酷航</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=ALL-2026WBCJP">2026WBC 東京機票價格整理</a></p>
                </div>
              </div>
            </div> -->
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #faa836">
                  <h5 class="card-title">台灣虎航</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=IT-NOLOOKBACK">不走回頭路 - 鳥取(米子)、岡山</a></p>
                </div>
              </div>
            </div> -->
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: red">
                  <h5 class="card-title">泰國獅子航空</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=SL-S25NGOLOW">名古屋低價</a></p>
                </div>
              </div>
            </div> -->
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #faa836">
                  <h5 class="card-title">台灣虎航</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=IT-VDSALE">白色情人節活動</a></p>
                </div>
              </div>
            </div> -->
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #f4dddf">
                  <h5 class="card-title">台灣虎航、酷航、樂桃航空、泰國獅子航空</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=ALL-TPESAKURA">台北東京櫻花季低價</a></p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #f4dddf">
                  <h5 class="card-title">台灣虎航、泰國亞洲航空</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=ALL-KHHSAKURA">高雄東京櫻花季低價</a></p>
                </div>
              </div>
            </div> -->
            <!-- <div class="col">
              <div class="card h-100">
                <img src="..." class="card-img-top" alt="...">
                <div class="card-body" style="color: aliceblue; background-color: #9b308c">
                  <h5 class="card-title">樂桃航空</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=MM-THANKS2025">感謝祭優惠活動（日本出發）</a></p>
                </div>
              </div>
            </div> -->
            <div class="col">
              <div class="card h-100">
                <!-- <img src="..." class="card-img-top" alt="..."> -->
                <div class="card-body" style="color: aliceblue; background-color: #9b308c">
                  <h5 class="card-title">樂桃航空</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=MM-S25JPLOW">25夏</a></p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <!-- <img src="..." class="card-img-top" alt="..."> -->
                <div class="card-body" style="color: black; background-color: #faa836">
                  <h5 class="card-title">台灣虎航</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=IT-S25JPLOW">25夏日本線</a></p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card h-100">
                <!-- <img src="..." class="card-img-top" alt="..."> -->
                <div class="card-body" style="color: black; background-color: #faa836">
                  <h5 class="card-title">台灣虎航</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=IT-S25KRLOW">25夏韓國線</a></p>
                </div>
              </div>
            </div>
            <!-- <div class="col">
              <div class="card h-100">
                <div class="card-body" style="color: black; background-color: #ffe900">
                  <h5 class="card-title">酷航</h5>
                  <p class="card-text fs-5"><a href="./Lowfare?ref=TR-S25LOW">25夏日本線</a></p>
                </div>
              </div>
            </div> -->
          </div>
          <!-- </div> -->
        </main>
        <!-- <div v-if="ads_flag">1111</div> -->
      </div>
    </div>
  </div>
</template>
<!-- <style lang="css" src="@/css/a.css" scoped></style> -->
<script>
  export default {
    // data: function () {
    //   return {
    //     ads_flag: false,
    //   }
    // },
  }
</script>
<style></style>
